<template>
    <v-row class="">
        <v-col sm="12" cols="12" class="pt-0">
            <h2 class="mb-0 font_20">{{ $t('user_pin') }}</h2>
            <v-dialog v-model="dialogm2" max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn class="white--text float-right text-capitalize" color="primary" v-on="on" @click="onNewClick">
                        {{ $t('create_new') }}
                    </v-btn>
                </template>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card>
                        <v-card-title>{{ $t('add_pin') }}</v-card-title>
                        <v-icon class="btn_close" @click="dialogm2 = false">close</v-icon>
                        <v-divider/>
                        <v-card-text style="height: 390px; background-color: #EDF1F5; color: #333333;">
                            <v-row>
                                <v-col sm="12" cols="12" class="pb-0">
                                    <label class="label">{{ $t('name') }}</label>
                                    <v-text-field
                                        class="mt-1"
                                        v-model="p.name"
                                        outlined
                                        placeholder=""
                                        :rules="[v => !!v || 'name is required']"
                                    />
                                    <label class="label">{{ $t('pin') }}</label>
                                    <!-- <v-text-field
                                        class="mt-1"
                                        v-model="p.pinCode"
                                        outlined
                                        placeholder=""
                                        :rules="[v => !!v || 'PIN is required']"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show1 ? 'text' : 'password'"
                                    /> -->
                                    <v-row>
                                        <v-col sm="12" col="12" class="input-wrapper">
                                        <PincodeInput 
                                            v-model="code"
                                            @change="codeChange"
                                            placeholder="0"
                                        />
                                        </v-col>
                                     
                                       
                                     
                                    </v-row>
                                    <v-col cols="12">
                                        <v-combobox
                                        v-model="select"
                                        :items="items"
                                        label="Assign to"
                                        multiple
                                        chips
                                        >
                                        <template v-slot:selection="data">
                                            <v-chip
                                            :key="JSON.stringify(data.item)"
                                            v-bind="data.attrs"
                                            :input-value="data.selected"
                                            :disabled="data.disabled"
                                            @click:close="data.parent.selectItem(data.item)"
                                            >
                                            <v-avatar
                                                class="accent white--text"
                                                left
                                                v-text="data.item.slice(0, 1).toUpperCase()"
                                            ></v-avatar>
                                            {{ data.item }}
                                            </v-chip>
                                        </template>
                                        </v-combobox>
                                    </v-col>

                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions class="pa-4">
                            <v-row>
                                <v-col sm="6" cols="6" class="py-0">
                                    <v-btn color="black"
                                           outlined
                                           class=" text-capitalize rounded-pill black--text float-left"
                                           @click="dialogm2 = false">{{ $t('cancel') }}
                                    </v-btn>
                                </v-col>
                                <v-col sm="6" cols="6" class="py-0">
                                    <v-btn color="secondary"
                                           class="px-3 rounded-pill white--text text-capitalize float-right"
                                           @click="saveClose">
                                        {{ $t('save_close') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>

                    </v-card>
                </v-form>
            </v-dialog>
        </v-col>
        <v-col sm="12" cols="12">
            <template>
                <v-simple-table class="attachment_table">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-uppercase">{{ $t('name') }}</th>
                            <th style="width: 50px"/>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="d in ps" v-bind:key="d.id">
                            <td>{{ d.name }}</td>
                            <td>
                                <v-btn class="btn_edit_setting" @click="onEditClick(d)">
                                    <v-icon class="white--text" size="12">fa fa-pen</v-icon>
                                    <span class="capitalize ml-1 white--text font_14">{{ $t('edit') }}</span>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </v-col>
    </v-row>
</template>

<script>
import PincodeInput from 'vue-pincode-input';
import UserPinModel from "@/scripts/model/UserPin"
const loanHandler = require("@/scripts/loanHandler")
export default {
    components: {
        PincodeInput
    },
    data: () => ({
        code:'0000',
        value: 1,
        select: ['Accounting & Budgeting'],
        items: [
          'Saving & credit',
          'Governance & Compliance',
          'Accounting & Budgeting',
          'JOINT-USE',
          'MARKETING',
          'INPUT SUPPLY',
        ],
        dialogm1: '',
        dialogm2: false,
        compeletLoading: true,
        isFullpage: false,
        snackbar: false,
        textStatus: 'Success',
        errors: [],
        groupMemberList: [],
        menuList: [
            {id: 1, name: 'Overview'},
            {id: 2, name: 'Saving'},
            {id: 3, name: 'Loan'},
            {id: 4, name: 'Member'},
            {id: 5, name: 'Finance'},
            {id: 6, name: 'Settings'}
        ],
        show1: false,
        //
        valid: true,
        saveOption: '',
        p: new UserPinModel({}),
        ps: [],
        oldps: [],
        edit: false,
    }),
    props: {},
    computed: {},
    watch: {
        dialogm2(val) {
            val || this.close()
        },
    },
    created() {
    },
    methods: {
        codeChange(){
            window.console.log(this.code)
        },
        async loadUserPin() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                    loanHandler.userPinGet().then(res => {
                        this.ps = res.data.data
                        this.oldps = res.data.data
                    })
                }, 300);
            })
        },
        async loadMemberGroup() {
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                    loanHandler.groupMemberGet().then(res => {
                        this.groupMemberList = res.data.data
                    })
                }, 300);
            })
        },
        async onEditClick(value) {
            this.edit = true
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved');
                    this.p = value
                    this.dialogm2 = true
                }, 300);
            })
        },
        onNewClick() {
            this.edit = false
            this.p = new UserPinModel({})
            this.p.id = ''
            this.clear()
        },
        saveClose() {
            this.saveOption = "saveClose"
            this.save()
        },
        async save() {
            if (!this.$refs.form.validate()) {
                this.$refs.form.validate()
                return
            }
            if(this.edit === false) {
                let dub = this.oldps.filter((obj) => {
                    return obj.name === this.p.name
                })
                if (dub.length > 0) {
                    this.$snotify.error('Dublicate Name')
                    return
                }
            }
            this.p.pinCode=this.code
            window.console.log(this.p, 'user pin data')
            new Promise(resolve => {
                setTimeout(() => {
                    resolve('resolved')
                    this.showLoading = true
                    loanHandler.userPinCreate(new UserPinModel(this.p)).then(response => {
                        this.showLoading = false
                        if (response.data.statusCode === 201) {
                            this.close()
                            this.loadUserPin()
                            this.$refs.form.reset()
                            this.$snotify.success('Successfully')
                        }
                    }).catch(e => {
                        this.$snotify.error('Something went wrong')
                        this.errors.push(e)
                        window.console.log(e)
                    })
                }, 300);
            })
        },
        close() {
            this.dialogm2 = false
        },
        clear() {
        },
    },
    mounted: async function () {
        await this.loadUserPin()
        await this.loadMemberGroup()
    }
};
</script>
<style scoped>
.attachment_table table tr th {
    height: 55px !important;
}

@media (max-width: 576px) {

}
</style>